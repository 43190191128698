<template>
  <div class="dsf-bbs-emoji">
    <img v-for="item in emojis" :key="item.key" :src="item.value" alt="" @click="handleClick(item)" />
  </div>
</template>

<script>
const context = require.context("_bbs/assets/styles/themes/img/emoji", false, /\.gif$/);
const emojis = context.keys().map((emoji) => {
  return {
    key: emoji.replace(/^\.\//, ""),
    value: context(emoji),
  };
});

export default {
  name: "DsfBbsEmoji",
  data() {
    return {
      emojis,
    };
  },
  methods: {
    handleClick(item) {
      this.$emit("change", item);
    },
  },
};
</script>
