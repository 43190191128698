<template>
  <dsf-bbs-views class="bbs-detail-views" :secondURL="$route.query.cname">
    <div ref="detail" class="bbs-detail" v-loading="detailLoading">
      <template v-if="detailContent">
        <h3>{{ detailContent.content.topicName }}</h3>
        <p class="detail-info">
          <span>楼主: {{ detailContent.content.userName }} </span><span>时间: {{ detailContent.content.dsCreateTime }}</span>
          <span v-if="detailContent.content.belongName"> {{ detailContent.content.belongName }}</span>
        </p>
        <div class="detail-content" v-html="detailContent.content.topicContent"></div>
        <div class="detail-imgs" v-if="detailContent.content.topicImg && detailContent.content.topicImg.length">
          <el-image
            v-for="(img, index) in detailContent.content.topicImg"
            :key="index"
            :src="img"
            fit="cover"
            :preview-src-list="detailContent.content.topicImg"
          ></el-image>
        </div>
        <dsf-upload-file v-if="detailContent.content.topicFile" :value="detailContent.content.topicFile" :read-only="true" label="附件" />
        <div class="bottom-buttons">
          <div></div>
          <div class="like-button">
            <el-button
              :disabled="like"
              @click="liked(detailContent.content.tagsNum)"
              v-if="detailContent.content.userId"
              :class="{ active: detailContent.content.liked == 1 }"
            >
              <i class="iconfont icon-dianzan_kuai"></i>
              <p>{{ detailContent.content.tagsNum }}</p>
            </el-button>
          </div>
          <div class="right-wrap" v-if="detailContent.content.userId">
            <el-button icon="el-icon-chat-line-square" size="small" @click="scrollFooter">回复</el-button>
            <el-button
              :class="{ active: detailContent.content.collected == 1 }"
              :icon="'el-icon-star-' + `${detailContent.content.collected == 1 ? 'on' : 'off'}`"
              size="small"
              @click="star(detailContent.content.collected)"
            >
              {{ detailContent.content.collected == 1 ? "已收藏" : "收藏" }}
            </el-button>
            <el-button size="small" @click="checkTpye">{{ queryParams.vType == 0 ? "只看楼主" : "全部" }}</el-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="not-data">
          {{ detailLoading ? "加载中..." : "暂无数据" }}
        </div>
      </template>
    </div>
    <bbs-reply-content ref="bbsReplyContent" :detail-content="detailContent" @queryData="queryData" />
  </dsf-bbs-views>
</template>

<script>
import DsfBbsViews from "../components/BbsViews";
import BbsReplyContent from "../components/BbsReplyContent";
export default {
  name: "DsfPageDetail",
  data() {
    return {
      like: false,
      queryParams: {
        topicId: this.$route.query.id,
        vType: 0,
        fPageNo: 1,
        fPageSize: 10,
      },
      queryUrl: "web/topic/detail",
      detailContent: null,
      detailLoading: false,
      detailParams: {
        ywlxValue: "bbs_topic",
        ywlxText: "BBS主题",
        ywid: "",
      },
    };
  },
  created() {
    // 查询板块下主题信息
    this.queryData();
  },
  methods: {
    // setData(newdata) {
    //   newdata.content.topicImg = newdata.content.topicImg ? this.getImgList(newdata.content.topicImg) : "";
    //   this.detailContent = newdata;
    // },
    queryData(fPageNo) {
      this.detailLoading = true;
      // 最新 最热
      if (dsf.isDef(fPageNo)) {
        this.queryParams.fPageNo = fPageNo;
      }
      this.$http
        .get(this.queryUrl, this.queryParams, $$webRoot.bbs)
        .done(({ success, data, message }) => {
          if (success) {
            this.detailParams.ywid = data.content.topicId;
            var content = data.content.topicContent;
            data.content.topicContent = content?.replace(/\n/g, "<br/>").replace(/\s/g, "&nbsp;");
            data.content.topicImg = data.content.topicImg ? this.getImgList(data.content.topicImg) : "";

            // 讨论内容
            var repliesData = data.replies.comment;
            if (repliesData.length) {
              repliesData.forEach((item, index) => {
                // 展开 收起 加载更多
                this.$set(item, "check", true);
                this.$set(item, "ismore", false);
                this.$set(item, "floor", (this.queryParams.fPageNo - 1) * this.queryParams.fPageSize + index + 1 + "楼");
                // 处理子级
                if (item.childList) {
                  _(item.childList).forEach((item) => {
                    if (item.plnr) {
                      item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                    }
                  });
                }
                // 当前评论
                if (item.plnr) {
                  // item.plnr = item.plnr.replace(/\n/g, "<br/>").replace(/\s/g, "&nbsp;");
                  item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                }

                item.img = item.img ? this.getImgList(item.img) : [];
              });
            }
            data.replies.comment = repliesData;
            this.detailContent = data;
            this.replyId = "";
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.detailLoading = false;
        });
    },
    liked(number) {
      var _this = this;
      _this.like = true;
      this.$http
        .post("dsfa/tags/saveOrCancel", this.detailParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            dsf.layer.message(res.message);
            this.detailContent.content.liked = res.data.isDo ? 1 : 0;
            this.detailContent.content.tagsNum = res.data.isDo ? Number(number) + 1 : Number(number) - 1;
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          // this.detailLoading = false;
          _this.like = false;
        });
    },
    star(collected) {
      if (collected == 1) {
        dsf.layer
          .confirm({
            title: "信息",
            message: "确认取消收藏吗？",
          })
          .then(() => {
            this.saveOrCancel();
          })
          .catch(() => {});
      } else {
        this.saveOrCancel();
      }
    },
    saveOrCancel() {
      this.$http
        .post("dsfa/collect/saveOrCancel", this.detailParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.detailContent.content.collected = res.data.isDo ? 1 : 0;
            dsf.layer.message(res.message);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    checkTpye() {
      this.queryParams.vType = this.queryParams.vType == 0 ? 1 : 0;
      // this.queryData();
      this.$refs?.bbsReplyContent.checkTpye();
    },
    getImgList(imgs) {
      if (!imgs || imgs === "[]" || _.isEqual(imgs, [])) {
        return [];
      }
      let _imgs = !_.isArray(imgs) ? JSON.parse(imgs) : imgs;
      return _imgs.map((v) => (v.relativePath ? dsf.url.getWebPath(v.relativePath) : v));
    },
    scrollFooter() {
      const scrollTop = this.$refs.detail.clientHeight + (this.$refs?.bbsReplyContent ? this.$refs.bbsReplyContent?.$refs?.content.clientHeight : 0);
      document.querySelectorAll(".ds-virtual-scroll-box")[0].scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
      this.$nextTick(() => {
        this.$refs?.bbsReplyContent.$refs.textarea.focus();
      });
    },
    // emojiChange(item, list) {
    //   let img = document.createElement("img");
    //   img.dataset.key = item.key;
    //   img.classList.add("emoji");
    //   img.src = item.value;
    //   if (list) {
    //     this.$refs["child-plnr-" + list.dsfaCommentId][0].appendChild(img);
    //   } else {
    //      this.$refs.bbsReplyContent.$refs.textarea.appendChild(img);
    //   }
    // },
    // reset() {
    //   this.$refs.bbsReplyContent.$refs.textarea.innerHTML = "";
    // },
  },
  components: {
    DsfBbsViews,
    BbsReplyContent,
  },
};
</script>
