import { render, staticRenderFns } from "./ErrorTips.vue?vue&type=template&id=6d61615e&"
import script from "./ErrorTips.vue?vue&type=script&lang=js&"
export * from "./ErrorTips.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/views/ErrorTips.vue"
export default component.exports