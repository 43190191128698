<template>
  <div class="pc-bbs-reply-content" v-loading="replyLoading">
    <div class="replies-content" ref="content">
      <template v-if="detailContent && detailContent.replies.comment.length">
        <div class="replies-list" v-for="item in detailContent.replies.comment" :key="item.dsfaCommentId">
          <div class="left">
            <div v-if="detailContent.content.userId == item.dsCreateUserId" class="self">
              <span class="louzhu">楼主</span>
            </div>
            <div class="user-info" @click="userInfo(item)">
              <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
                <i class="el-icon-picture-outline-round" style="font-size: 90px; line-height: 90px"></i>
              </el-avatar>
              <p class="user-name">{{ item.dsCreateUserName }}</p>
              <el-tooltip :content="item.belongName" placement="bottom" effect="light">
                <p class="user-name belong" :style="{ visibility: item.belongName ? 'visible' : 'hidden' }">
                  {{ item.belongName }}
                </p>
              </el-tooltip>
            </div>
          </div>
          <div class="right">
            <div class="plnr" v-html="item.plnr"></div>
            <div class="reply-images" v-if="item.img && item.img.length">
              <el-image v-for="(img, index) in item.img" :key="index" :src="img" fit="cover" :preview-src-list="item.img" />
            </div>
            <div class="floor-page">
              <span>{{ item.floor }}</span>
              <span>{{ item.showTime }}</span>
              <span class="floor-reply" @click="replyShow(item)">回复</span>

              <div class="children-input" v-if="replyId == item.dsfaCommentId">
                <div class="dsf-bbs-textarea-box">
                  <div
                    :ref="`child-plnr-${item.dsfaCommentId}`"
                    class="dsf-bbs-detail-textarea"
                    placeholder="请输入你的回复"
                    contenteditable
                    @blur="addBlur(item.dsfaCommentId)"
                  ></div>

                  <div class="dsf-bbs-textarea-tips">
                    当前已输入{{ currentLength }}个字符, 您还可以输入{{ maxLength - currentLength >= 0 ? maxLength - currentLength : 0 }}个字符。
                  </div>
                </div>
                <div class="children-emoji-btn">
                  <div class="flex-emoji">
                    <el-popover :append-to-body="false" placement="top-start" width="942" trigger="click">
                      <bbs-emoji @change="emojiChange($event, item)" />
                      <i slot="reference" class="iconfont icon-xiaolianmanyifuwu"></i>
                    </el-popover>
                  </div>
                  <el-button size="small" class="submit-btn" @click="comment(item)">回复</el-button>
                </div>
              </div>
            </div>
            <div class="child-list" v-if="item.childList && item.childList.length">
              <p class="expand-btn">
                <span @click="item.check = !item.check" :class="{ active: item.check }"> {{ item.check ? "收起" : "展开" }}回复</span>
              </p>
              <div class="expand-content" v-show="item.check">
                <template v-for="(child, len) in item.childList">
                  <div class="expand-item" :key="child.dsfaCommentId" :data-len="len">
                    <div class="user-info" @click="userInfo(child)">
                      <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + child.dsCreateUserId" @error="errorHandler">
                        <i class="el-icon-picture-outline-round" style="font-size: 40px; line-height: 40px"></i>
                      </el-avatar>
                    </div>
                    <div class="expand-plrn">
                      <p v-if="child.dsCreateUserId !== child.bplrValue" class="plnr">
                        <span class="user">{{ child.dsCreateUserName }}</span>
                        &nbsp;回复&nbsp;
                        <span class="user">{{ child.bplrText }}：</span>
                        <span v-html="child.plnr"></span>
                      </p>
                      <p v-else class="plnr">
                        <span class="user">{{ child.bplrText }}：</span><span v-html="child.plnr"></span>
                      </p>
                      <div class="time">
                        <div class="t time-belong">
                          <span>{{ child.dsCreateTime }}</span>
                          <el-tooltip v-if="child.belongName" :content="child.belongName" placement="bottom" effect="light">
                            <span class="belong" v-if="child.belongName"> {{ child.belongName }}</span>
                          </el-tooltip>
                        </div>
                        <span class="btn" @click="replyShow(child)">回复</span>
                      </div>
                      <div class="children-input" v-if="replyId == child.dsfaCommentId">
                        <div class="dsf-bbs-textarea-box">
                          <div
                            :ref="`child-plnr-${child.dsfaCommentId}`"
                            class="dsf-bbs-detail-textarea"
                            placeholder="请输入你的回复"
                            contenteditable
                            @blur="addBlur(child.dsfaCommentId)"
                          ></div>
                          <div class="dsf-bbs-textarea-tips">
                            当前已输入{{ currentLength }}个字符, 您还可以输入{{
                              maxLength - currentLength >= 0 ? maxLength - currentLength : 0
                            }}个字符。
                          </div>
                        </div>
                        <div class="children-emoji-btn">
                          <div class="flex-emoji">
                            <el-popover :append-to-body="false" placement="top-start" width="942" trigger="click">
                              <bbs-emoji @change="emojiChange($event, child)" />
                              <i slot="reference" class="iconfont icon-xiaolianmanyifuwu"></i>
                            </el-popover>
                          </div>
                          <el-button size="small" class="submit-btn" @click="comment(child)">回复</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <p class="more" v-if="item.check && item.childList.length > 3" @click="queryChildComment(item)">
                {{ item.ismore ? "没有更多了..." : "更多评论" }}
              </p>
            </div>
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryParams.fPageNo"
          :page-size="queryParams.fPageSize"
          :total="detailContent.replies.total"
          @current-change="currentChange"
        />
      </template>
      <div v-else class="not-data">暂无数据</div>
    </div>

    <el-tabs v-model="activeName" class="reply-wrapper" v-if="detailContent">
      <el-tab-pane label="发表回复" name="allPage">
        <div class="dsf-bbs-textarea-box">
          <div ref="textarea" class="dsf-bbs-detail-textarea" placeholder="请输入你的回复" contenteditable @input="textInput" @keyup="textInput"></div>
          <div class="file-imgs" v-loading="upfileLoading">
            <div v-for="(item, index) in fileList" class="item" :key="item.id">
              <el-image :src="dsf.url.getWebPath(item.relativePath)" :alt="item.originalFileName" :preview-src-list="srcList" fit="contain" />
              <i class="del iconfont icon-icon2" @click="handleRemove(index)" title="删除"></i>
            </div>
          </div>
          <div class="dsf-bbs-textarea-tips">
            当前已输入{{ bottomLength }}个字符, 您还可以输入{{ maxLength - bottomLength >= 0 ? maxLength - bottomLength : 0 }}个字符。
          </div>
        </div>
        <div class="reply-button-wrapper">
          <div class="icon-upload">
            <el-popover class="popover" :append-to-body="false" placement="right" width="942" trigger="click">
              <bbs-emoji @change="emojiChange" />
              <el-button slot="reference" class="iconfont icon-xiaolianmanyifuwu" title="表情"></el-button>
            </el-popover>
            <el-upload
              class="reply-upload"
              ref="upload"
              multiple
              :accept="uploadFileExts"
              :headers="headersParam"
              :action="requestUrl"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-exceed="handleExceed"
              :limit="parseInt(maxCount)"
            >
              <el-button class="btn" icon="iconfont icon-tupiantianjia" title="图片上传"></el-button>
            </el-upload>
          </div>

          <el-button class="comment-button" :disabled="btnDisabled" @click="comment">{{ btnText }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BbsEmoji from "../components/BbsEmoji";

export default dsf.component({
  name: "DsfPcBbsReplyContent",
  ctrlCaption: "PC评论组件",
  mixins: [$mixins.control],
  props: {
    ywlxValue: {
      type: String,
      default: "bbs_topic",
    },
    detailContent: {
      type: Object,
      default() {
        return {};
      },
    },
    // 获取详情的请求地址
    commentListUrl: {
      type: String,
      default: "/web/topic/detail",
    },
    // 评论的请求地址
    replyUrl: {
      type: String,
      default: "/dsfa/platform/comment/addOrRefreshComment",
    },
    // 回复列表的请求地址
    replyListUrl: {
      type: String,
      default: "/dsfa/platform/comment/queryChildComment",
    },
    btnText: {
      type: String,
      default: "发表",
    },
    maxLength: {
      type: Number,
      default: 500,
    },
    replyStatus: {
      type: Boolean,
      default: false,
    },
    queryParams: {
      type: Object,
      default() {
        return {
          topicId: "",
          vType: 0,
          fPageNo: 1,
          fPageSize: 10,
        };
      },
    },
    requestUrl: {
      type: String,
      default: "file/upload/",
    },
    uploadFileExts: {
      type: String,
      default: "image/jpeg,image/jpg,image/png",
    },
    maxCount: {
      type: [Number, String],
      default: 9,
    },

  },
  data() {
    return {
      activeName: "allPage",
      replyLoading: false,
      // queryParams: {
      //   topicId: "",
      //   vType: 0,
      //   fPageNo: 1,
      //   fPageSize: 10,
      // },
      // detailContent: null,
      replyId: "",
      currentLength: 0,
      bottomLength: 0,
      fileList: [],
      upfileLoading: false,
      percentage: 0,
      headersParam: {
        authorization_token: dsf.getToken(),
      },
      btnDisabled: true
    };
  },
  created() {
    this.queryParams.topicId = this.$route.query.id;
    // 查询板块下主题信息
    if (this.isDesign) {
      this.detailContent = {
        content: {
          userName: "小明",
          userId: "001",
        },
        replies: {
          total: 1,
          comment: [
            {
              dsCreateUserId: "001",
              dsCreateUserName: "小明",
              dsCreateTime: "2021-11-01 00:00:00",
              showTime: "1小时前",
              plnr: "这是小明的回复。",
              childList: [],
            },
          ],
        },
      };
    } else {
      // 父组件传数据
      // this.queryData();
    }
  },
  computed: {
    srcList() {
      return this.fileList.map((v) => {
        if (v.id) {
          return v.relativePath;
        }
        return dsf.url.getWebPath(v.relativePath);
      });
    },
  },
  mounted() {},
  updated() {},
  methods: {
    currentChange(fPageNo) {
      this.$emit("queryData", fPageNo);
    },

    queryReplyData() {
      this.replyId = "";
      this.$emit("queryData");
      this.keyUp();
    },
    replyShow(item) {
      if (!item.dsfaCommentId) return;
      // 当前已点开 就缩回
      this.replyId = this.replyId != item.dsfaCommentId ? item.dsfaCommentId : "";
      setTimeout(() => {
        if (this.replyId) {
          this.keyUp("child-plnr-" + item.dsfaCommentId);
        }
      }, 500);
    },
    textInput(e) {
      let btnStatus = e.target.innerHTML && e.target.innerHTML.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "").length > 0 ? false : true;
      this.currentLength = this.getTextLength(this.$refs.textarea.innerHTML);
      this.btnDisabled = this.currentLength > this.maxLength ? true : btnStatus;
    },
    getTextLength(innerHTML) {
      var mytext = innerHTML.replace(new RegExp("&nbsp;", "g"), ""); //删除空格"&nbsp;"
      var textarr = mytext.split("<");
      var contentlength = 0;
      _.forEach(textarr, function (item) {
        var index = item.indexOf(">");
        if (item[index] !== -1) {
          contentlength = contentlength + item.substr(index + 1, item.length).length;
        }
      });
      var imgNum = this.searchTimes(innerHTML, "<img"); //图片个数
      var spaceNum = this.searchTimes(innerHTML, "&nbsp;"); //空格个数
      contentlength = contentlength + imgNum + spaceNum;
      return contentlength;
    },
    searchTimes(text, word) {
      var index = text.indexOf(word);
      var num = 0;
      while (index !== -1) {
        num++;
        index = text.indexOf(word, index + 1);
      }
      return num;
    },
    addBlur(dsfaCommentId) {
      if (dsfaCommentId) {
        var type = "child-plnr-" + dsfaCommentId;
        this.currentLength = this.getTextLength(this.$refs[type][0].innerHTML);
      } else {
        this.bottomLength = this.getTextLength(this.$refs.textarea.innerHTML);
      }
    },

    keyUp(type) {
      this.currentLength = 0;
      this.bottomLength = 0;
      if (type) {
        this.$refs[type][0].addEventListener("keyup", () => {
          this.currentLength = this.getTextLength(this.$refs[type][0].innerHTML);
        });
      } else {
        // 底部的评论框
        this.$refs.textarea.addEventListener("keyup", () => {
          this.bottomLength = this.getTextLength(this.$refs.textarea.innerHTML);
        });
      }
    },
    comment(item) {
      let params = {
        plnr: '',
        img: null,
        ywlxValue: this.ywlxValue,
        ywid: this.detailContent.content.topicId,
        treeinfoPid: item.dsfaCommentId,
        bplrText: this.detailContent.content.userName,
        bplrValue: this.detailContent.content.userId,
      };
      let imgList = null;
      var plnrlength = 0;
      if (item.dsfaCommentId) {
        params.plnr = this.getContent("child-plnr-" + item.dsfaCommentId);
        plnrlength = this.currentLength;
      } else {
        // 过滤掉上传失败的图片
        if (this.fileList.length) {
          imgList = this.fileList.filter((f) => !!f);
          if (imgList.length < this.fileList.length) {
            dsf.layer
              .confirm({
                message: "上传失败的图片将被忽略，是否继续？",
                confirmButtonText: "继续",
              })
              .then(() => {
                params.img = JSON.stringify(imgList);
              })
              .catch(() => {});
          } else {
            params.img = JSON.stringify(imgList);
          }
        }

        params.plnr = this.getContent();
        plnrlength = this.bottomLength;
      }
      if (params.plnr == "" && !params.img) {
        plnrlength = 0;
        dsf.layer.message("请输入内容~", false);
        return;
      }
      if (plnrlength > this.maxLength) {
        dsf.layer.message(`当前字数为${plnrlength}字,评论字数限制在${this.maxLength}字以内`, false);
        return;
      }

      this.$http
        .post(this.replyUrl, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            dsf.layer.message(res.message);
            this.reset();
            this.queryReplyData();
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    queryChildComment(item) {
      if (item.ismore) return;
      let params = {
        childPageNo: 1,
        childPageSize: 10000,
        businessType: this.ywlxValue,
        businessId: this.detailContent.content.topicId,
        pId: item.dsfaCommentId,
      };
      this.$http
        .get(this.replyListUrl, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            item.ismore = true;
            if (res.data && res.data.dsfaCommentBoList.length) {
              _(res.data.dsfaCommentBoList).forEach((item) => {
                if (item.plnr) {
                  item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                }
              });
              item.childList = res.data.dsfaCommentBoList;
              // item.childList.push(...res.data);
            }
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    errorHandler() {               
      return true;
    },
    userInfo(data) {
      if (data.dsfaCommentId && data.dsCreateUserId) {
        this.$openWindow("#/pc/bbs/home?u=" + data.dsCreateUserId);
      }
    },
    // 只看楼主
    checkTpye() {
      this.queryParams.vType = this.queryParams.vType == 0 ? 1 : 0;
      this.queryReplyData();
    },
    getContent(type) {
      let text = null;
      if (type) {
        text = this.$refs[type][0].innerHTML;
      } else {
        // 底部的评论框
        text = this.$refs.textarea.innerHTML;
      }
      text = text.replace(/(^\s*|&nbsp;)|(\s*$)/g, "");
      return text;
    },
    emojiChange(item, list) {
      let img = document.createElement("img");
      img.dataset.key = item.key;
      img.classList.add("emoji");
      img.src = item.value;
      if (list) {
        this.$refs["child-plnr-" + list.dsfaCommentId][0].appendChild(img);
        this.currentLength += 1;
      } else {
        this.$refs.textarea.appendChild(img);
        this.bottomLength += 1;
      }
      this.btnDisabled = false;
    },
    reset() {
      this.fileList = [];
      this.btnDisabled = true;
      this.$refs.textarea.innerHTML = "";
    },
    handleRemove(index) {
      this.$refs.upload.uploadFiles.splice(index, 1);
      this.fileList.splice(index, 1);
      let text = this.$refs.textarea.innerHTML.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "").length > 0;
      this.btnDisabled = this.fileList.length > 0 || text ? false : true;
    },
    beforeUpload(file) {
      // 判断文件格式
      if (this.uploadFileExts) {
        let arr = _.filter(this.uploadFileExts?.split?.(",") || [], (it) => it);
        if (!~arr.indexOf(file.type)) {
          dsf.layer.message("上传图片格式不正确", false);
          return false;
        }
      }
    },
    handleExceed() {
      dsf.layer.message(`当前最多上传 ${this.maxCount} 张图片`, false);
    },
    //上传失败
    handleError() {
      this.upfileLoading = false;
      dsf.layer.message("上传失败", false);
    },
    //上传成功
    handleSuccess(res) {
      this.upfileLoading = false;
      if (res.success && res.state == 20000) {
        this.btnDisabled = false;
        // console.log(res, this.fileList);
        // let img = this.getImgList(res.data);
        this.fileList.push(...res.data);
        // this.percentage = 100;
        // dsf.storage.session.set("fileList", this.fileList);
      } else {
        dsf.layer.message(res.message, false);
      }
    },
    replyImgList(imgs, type) {
      if (!imgs || imgs === "[]" || _.isEqual(imgs, [])) {
        return [];
      }
      let _imgs = !_.isArray(imgs) ? JSON.parse(imgs) : imgs;
      let compSole = type == "list" ? "/bbs/image/compSole?scale=3&srcPath=" : "";
      return _imgs.map((v) => (v.relativePath ? dsf.url.getWebPath(compSole + v.relativePath) : v));
    },
  },
  components: {
    BbsEmoji,
  },
  watch: {
    detailContent(val) {
      if (val.content) {
        setTimeout(() => {
          this.keyUp();
        }, 500);
      }
    },
  },
});
</script>
