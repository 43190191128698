var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.replyLoading,
          expression: "replyLoading",
        },
      ],
      staticClass: "pc-bbs-reply-content",
    },
    [
      _c(
        "div",
        { ref: "content", staticClass: "replies-content" },
        [
          _vm.detailContent && _vm.detailContent.replies.comment.length
            ? [
                _vm._l(_vm.detailContent.replies.comment, function (item) {
                  return _c(
                    "div",
                    { key: item.dsfaCommentId, staticClass: "replies-list" },
                    [
                      _c("div", { staticClass: "left" }, [
                        _vm.detailContent.content.userId == item.dsCreateUserId
                          ? _c("div", { staticClass: "self" }, [
                              _c("span", { staticClass: "louzhu" }, [
                                _vm._v("楼主"),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "user-info",
                            on: {
                              click: function ($event) {
                                return _vm.userInfo(item)
                              },
                            },
                          },
                          [
                            _c(
                              "el-avatar",
                              {
                                attrs: {
                                  src:
                                    "/oua2/getUserPhoto?type=relativePath&userId=" +
                                    item.dsCreateUserId,
                                },
                                on: { error: _vm.errorHandler },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline-round",
                                  staticStyle: {
                                    "font-size": "90px",
                                    "line-height": "90px",
                                  },
                                }),
                              ]
                            ),
                            _c("p", { staticClass: "user-name" }, [
                              _vm._v(_vm._s(item.dsCreateUserName)),
                            ]),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: item.belongName,
                                  placement: "bottom",
                                  effect: "light",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "user-name belong",
                                    style: {
                                      visibility: item.belongName
                                        ? "visible"
                                        : "hidden",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.belongName) + " ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c("div", {
                          staticClass: "plnr",
                          domProps: { innerHTML: _vm._s(item.plnr) },
                        }),
                        item.img && item.img.length
                          ? _c(
                              "div",
                              { staticClass: "reply-images" },
                              _vm._l(item.img, function (img, index) {
                                return _c("el-image", {
                                  key: index,
                                  attrs: {
                                    src: img,
                                    fit: "cover",
                                    "preview-src-list": item.img,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "floor-page" }, [
                          _c("span", [_vm._v(_vm._s(item.floor))]),
                          _c("span", [_vm._v(_vm._s(item.showTime))]),
                          _c(
                            "span",
                            {
                              staticClass: "floor-reply",
                              on: {
                                click: function ($event) {
                                  return _vm.replyShow(item)
                                },
                              },
                            },
                            [_vm._v("回复")]
                          ),
                          _vm.replyId == item.dsfaCommentId
                            ? _c("div", { staticClass: "children-input" }, [
                                _c(
                                  "div",
                                  { staticClass: "dsf-bbs-textarea-box" },
                                  [
                                    _c("div", {
                                      ref: "child-plnr-" + item.dsfaCommentId,
                                      refInFor: true,
                                      staticClass: "dsf-bbs-detail-textarea",
                                      attrs: {
                                        placeholder: "请输入你的回复",
                                        contenteditable: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.addBlur(item.dsfaCommentId)
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "dsf-bbs-textarea-tips" },
                                      [
                                        _vm._v(
                                          " 当前已输入" +
                                            _vm._s(_vm.currentLength) +
                                            "个字符, 您还可以输入" +
                                            _vm._s(
                                              _vm.maxLength -
                                                _vm.currentLength >=
                                                0
                                                ? _vm.maxLength -
                                                    _vm.currentLength
                                                : 0
                                            ) +
                                            "个字符。 "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "children-emoji-btn" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-emoji" },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              "append-to-body": false,
                                              placement: "top-start",
                                              width: "942",
                                              trigger: "click",
                                            },
                                          },
                                          [
                                            _c("bbs-emoji", {
                                              on: {
                                                change: function ($event) {
                                                  return _vm.emojiChange(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                            }),
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-xiaolianmanyifuwu",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "submit-btn",
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.comment(item)
                                          },
                                        },
                                      },
                                      [_vm._v("回复")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        item.childList && item.childList.length
                          ? _c("div", { staticClass: "child-list" }, [
                              _c("p", { staticClass: "expand-btn" }, [
                                _c(
                                  "span",
                                  {
                                    class: { active: item.check },
                                    on: {
                                      click: function ($event) {
                                        item.check = !item.check
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.check ? "收起" : "展开") +
                                        "回复"
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.check,
                                      expression: "item.check",
                                    },
                                  ],
                                  staticClass: "expand-content",
                                },
                                [
                                  _vm._l(item.childList, function (child, len) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: child.dsfaCommentId,
                                          staticClass: "expand-item",
                                          attrs: { "data-len": len },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "user-info",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.userInfo(child)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-avatar",
                                                {
                                                  attrs: {
                                                    src:
                                                      "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                      child.dsCreateUserId,
                                                  },
                                                  on: {
                                                    error: _vm.errorHandler,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-picture-outline-round",
                                                    staticStyle: {
                                                      "font-size": "40px",
                                                      "line-height": "40px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "expand-plrn" },
                                            [
                                              child.dsCreateUserId !==
                                              child.bplrValue
                                                ? _c(
                                                    "p",
                                                    { staticClass: "plnr" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "user" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              child.dsCreateUserName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("  回复  "),
                                                      _c(
                                                        "span",
                                                        { staticClass: "user" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              child.bplrText
                                                            ) + "："
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            child.plnr
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "p",
                                                    { staticClass: "plnr" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "user" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              child.bplrText
                                                            ) + "："
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            child.plnr
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "t time-belong",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            child.dsCreateTime
                                                          )
                                                        ),
                                                      ]),
                                                      child.belongName
                                                        ? _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                content:
                                                                  child.belongName,
                                                                placement:
                                                                  "bottom",
                                                                effect: "light",
                                                              },
                                                            },
                                                            [
                                                              child.belongName
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "belong",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            child.belongName
                                                                          )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.replyShow(
                                                            child
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("回复")]
                                                  ),
                                                ]
                                              ),
                                              _vm.replyId == child.dsfaCommentId
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "children-input",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dsf-bbs-textarea-box",
                                                        },
                                                        [
                                                          _c("div", {
                                                            ref:
                                                              "child-plnr-" +
                                                              child.dsfaCommentId,
                                                            refInFor: true,
                                                            staticClass:
                                                              "dsf-bbs-detail-textarea",
                                                            attrs: {
                                                              placeholder:
                                                                "请输入你的回复",
                                                              contenteditable:
                                                                "",
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.addBlur(
                                                                  child.dsfaCommentId
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dsf-bbs-textarea-tips",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 当前已输入" +
                                                                  _vm._s(
                                                                    _vm.currentLength
                                                                  ) +
                                                                  "个字符, 您还可以输入" +
                                                                  _vm._s(
                                                                    _vm.maxLength -
                                                                      _vm.currentLength >=
                                                                      0
                                                                      ? _vm.maxLength -
                                                                          _vm.currentLength
                                                                      : 0
                                                                  ) +
                                                                  "个字符。 "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "children-emoji-btn",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex-emoji",
                                                            },
                                                            [
                                                              _c(
                                                                "el-popover",
                                                                {
                                                                  attrs: {
                                                                    "append-to-body": false,
                                                                    placement:
                                                                      "top-start",
                                                                    width:
                                                                      "942",
                                                                    trigger:
                                                                      "click",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "bbs-emoji",
                                                                    {
                                                                      on: {
                                                                        change: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.emojiChange(
                                                                            $event,
                                                                            child
                                                                          )
                                                                        },
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "iconfont icon-xiaolianmanyifuwu",
                                                                    attrs: {
                                                                      slot:
                                                                        "reference",
                                                                    },
                                                                    slot:
                                                                      "reference",
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "submit-btn",
                                                              attrs: {
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.comment(
                                                                    child
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("回复")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              item.check && item.childList.length > 3
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "more",
                                      on: {
                                        click: function ($event) {
                                          return _vm.queryChildComment(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.ismore
                                              ? "没有更多了..."
                                              : "更多评论"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next",
                    "current-page": _vm.queryParams.fPageNo,
                    "page-size": _vm.queryParams.fPageSize,
                    total: _vm.detailContent.replies.total,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.queryParams, "fPageNo", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.queryParams, "fPageNo", $event)
                    },
                    "current-change": _vm.currentChange,
                  },
                }),
              ]
            : _c("div", { staticClass: "not-data" }, [_vm._v("暂无数据")]),
        ],
        2
      ),
      _vm.detailContent
        ? _c(
            "el-tabs",
            {
              staticClass: "reply-wrapper",
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "发表回复", name: "allPage" } },
                [
                  _c("div", { staticClass: "dsf-bbs-textarea-box" }, [
                    _c("div", {
                      ref: "textarea",
                      staticClass: "dsf-bbs-detail-textarea",
                      attrs: {
                        placeholder: "请输入你的回复",
                        contenteditable: "",
                      },
                      on: { input: _vm.textInput, keyup: _vm.textInput },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.upfileLoading,
                            expression: "upfileLoading",
                          },
                        ],
                        staticClass: "file-imgs",
                      },
                      _vm._l(_vm.fileList, function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "item" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: _vm.dsf.url.getWebPath(item.relativePath),
                                alt: item.originalFileName,
                                "preview-src-list": _vm.srcList,
                                fit: "contain",
                              },
                            }),
                            _c("i", {
                              staticClass: "del iconfont icon-icon2",
                              attrs: { title: "删除" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "dsf-bbs-textarea-tips" }, [
                      _vm._v(
                        " 当前已输入" +
                          _vm._s(_vm.bottomLength) +
                          "个字符, 您还可以输入" +
                          _vm._s(
                            _vm.maxLength - _vm.bottomLength >= 0
                              ? _vm.maxLength - _vm.bottomLength
                              : 0
                          ) +
                          "个字符。 "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "reply-button-wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "icon-upload" },
                        [
                          _c(
                            "el-popover",
                            {
                              staticClass: "popover",
                              attrs: {
                                "append-to-body": false,
                                placement: "right",
                                width: "942",
                                trigger: "click",
                              },
                            },
                            [
                              _c("bbs-emoji", {
                                on: { change: _vm.emojiChange },
                              }),
                              _c("el-button", {
                                staticClass: "iconfont icon-xiaolianmanyifuwu",
                                attrs: { slot: "reference", title: "表情" },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "reply-upload",
                              attrs: {
                                multiple: "",
                                accept: _vm.uploadFileExts,
                                headers: _vm.headersParam,
                                action: _vm.requestUrl,
                                "show-file-list": false,
                                "before-upload": _vm.beforeUpload,
                                "on-success": _vm.handleSuccess,
                                "on-error": _vm.handleError,
                                "on-exceed": _vm.handleExceed,
                                limit: parseInt(_vm.maxCount),
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "btn",
                                attrs: {
                                  icon: "iconfont icon-tupiantianjia",
                                  title: "图片上传",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "comment-button",
                          attrs: { disabled: _vm.btnDisabled },
                          on: { click: _vm.comment },
                        },
                        [_vm._v(_vm._s(_vm.btnText))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }