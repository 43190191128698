var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dsf-bbs-views",
    {
      attrs: {
        "page-title": _vm.pageTitle,
        showSorted: _vm.showSorted,
        managerStatus: _vm.managerStatus,
      },
      on: { sorted: _vm.sortedQuery },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "全部", name: "allPage" } },
            [
              _c("dsf-list-content", {
                attrs: {
                  managerStatus: _vm.managerStatus,
                  "list-content": _vm.listContent,
                },
                on: { "page-event": _vm.queryData, sorted: _vm.sortedQuery },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }