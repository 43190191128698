var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "ds-bbs-page bbs-view" },
    [
      _c(
        "el-header",
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "home" } } }, [
                _c("i", { staticClass: "iconfont icon-home" }),
                _vm._v(" 论坛首页"),
              ]),
              _vm.$route.name != "allPage" &&
              _vm.$route.name != "myPost" &&
              _vm.$route.name != "myReply"
                ? _c(
                    "el-breadcrumb-item",
                    { attrs: { to: { path: _vm.path } } },
                    [_vm._v(_vm._s(_vm.secondURL ? _vm.secondURL : "所有帖子"))]
                  )
                : _vm._e(),
              _c("el-breadcrumb-item", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.pageTitle ||
                        _vm.$route.params.title ||
                        _vm.$route.meta.title
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.showSorted && !_vm.managerStatus
            ? _c(
                "el-button",
                { staticClass: "create-post", on: { click: _vm.handleClick } },
                [_vm._v("发布帖子")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "DsfVirtualScroll",
        { staticClass: "list" },
        [
          _c(
            "el-main",
            [
              _vm._t("default"),
              _vm.showSorted && _vm.$route.meta.title != "帖子详情"
                ? _c(
                    "div",
                    { staticClass: "sort-button" },
                    [
                      _vm._l(_vm.sortedData, function (item, index) {
                        return [
                          !_vm.managerStatus
                            ? _c(
                                "span",
                                {
                                  key: index,
                                  class: { active: index == _vm.checkSort },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSorted(index, item.text)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm.managerStatus
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.changeType },
                              model: {
                                value: _vm.selectvalue,
                                callback: function ($$v) {
                                  _vm.selectvalue = $$v
                                },
                                expression: "selectvalue",
                              },
                            },
                            _vm._l(_vm.selection, function (item) {
                              return _c("el-option", {
                                key: item.text,
                                attrs: { label: item.text, value: item.text },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }