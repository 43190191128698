var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.bbsLoading,
          expression: "bbsLoading",
        },
      ],
      staticClass: "ds-bbs-page",
    },
    [
      _c(
        "el-aside",
        { attrs: { width: "240px" } },
        [
          _c(
            "div",
            { staticClass: "head-img" },
            [
              _vm.userId
                ? _c(
                    "el-avatar",
                    {
                      attrs: { src: _vm.userPhoto },
                      on: { error: _vm.errorHandler },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.dsf.config.setting_public_user_default_header,
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.userData.user
                ? _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "center" } },
                    [
                      _c("el-col", [
                        _c("p", [
                          _vm._v(_vm._s(_vm.userData.user.replies || 0)),
                        ]),
                        _c("p", [_vm._v("回复")]),
                      ]),
                      _c("el-col", [
                        _c("p", [_vm._v(_vm._s(_vm.userData.user.like || 0))]),
                        _c("p", [_vm._v("点赞")]),
                      ]),
                      _c("el-col", [
                        _c("p", [_vm._v(_vm._s(_vm.userData.user.liked || 0))]),
                        _c("p", { staticClass: "txt" }, [_vm._v("被赞")]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("DsfVirtualScroll", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "bbs-nav-menu" },
              [
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "default-active": _vm.defaultActive,
                      "default-openeds": _vm.defaultOpeneds,
                    },
                    on: { select: _vm.handleSelect, open: _vm.handleOpen },
                  },
                  [
                    _vm._l(_vm.menuNav, function (meun, index) {
                      return [
                        meun.viewStatus === "1" && !meun.children
                          ? _c(
                              "el-menu-item",
                              {
                                key: index,
                                attrs: {
                                  index: meun.path + "-" + meun.catalogName,
                                },
                              },
                              [
                                _c("i", { class: meun.icon }),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(meun.catalogName) + " ")]
                                ),
                              ]
                            )
                          : meun.viewStatus === "1"
                          ? _c(
                              "el-submenu",
                              {
                                key: index,
                                attrs: { "data-id": index, index: meun.path },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("i", { staticClass: "el-icon-location" }),
                                  _c("span", [
                                    _vm._v(_vm._s(meun.catalogName)),
                                  ]),
                                ]),
                                _c(
                                  "el-menu-item-group",
                                  _vm._l(meun.children, function (child, key) {
                                    return _c(
                                      "el-menu-item",
                                      {
                                        key: key,
                                        attrs: {
                                          title: child.catalogName,
                                          index:
                                            child.catalogId +
                                            "-" +
                                            child.catalogName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(child.catalogName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.userDynamic,
              expression: "userDynamic",
            },
          ],
          staticStyle: { overflow: "auto" },
          attrs: {
            width: "940px",
            "infinite-scroll-disabled": _vm.scrollDisabled,
            "infinite-scroll-delay": 500,
          },
        },
        [
          _vm.publishTheme
            ? _c("dsf-view-part", {
                attrs: {
                  path: _vm.postReview,
                  "show-model": "full",
                  independent: true,
                },
              })
            : [
                _vm.userData.accessType != 1
                  ? [
                      _c(
                        "div",
                        { staticClass: "other-card" },
                        [
                          _c("div", { staticClass: "other-name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userData.user &&
                                    _vm.userData.user.userName
                                ) +
                                " "
                            ),
                            _c("i", { staticClass: "el-icon-user-solid" }),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                plain: "",
                                icon: _vm.followed
                                  ? "el-icon-close"
                                  : "el-icon-plus",
                              },
                              on: { click: _vm.handleFollow },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.followed ? "取消关注" : "关注") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-card",
                        { staticClass: "hot-invitation other-content" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-title",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("h3", [_vm._v("他的帖子")]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOther("other")
                                    },
                                  },
                                },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                          _c("dsf-list-content", {
                            attrs: {
                              "list-content": _vm.userData.otherTopics,
                              "show-page": false,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-card",
                        { staticClass: "hot-reply" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-title",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("h3", [_vm._v("他的回复")]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOther("reply")
                                    },
                                  },
                                },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                          _vm.userData.reply && _vm.userData.reply.data
                            ? _vm._l(
                                _vm.userData.reply.data,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "reply-list" },
                                    [
                                      _c("div", { staticClass: "reply-item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "user-head" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "user-photo" },
                                              [
                                                _c(
                                                  "el-avatar",
                                                  {
                                                    attrs: {
                                                      src:
                                                        "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                        item.dsCreateUserId,
                                                    },
                                                    on: {
                                                      error: _vm.errorHandler,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-picture-outline-round",
                                                      staticStyle: {
                                                        "font-size": "20px",
                                                        "line-height": "40px",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "reply-box" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.dsCreateUserName
                                                        ) +
                                                        " "
                                                    ),
                                                    item.plnr
                                                      ? _c("span", {
                                                          staticClass:
                                                            "comment-plnr",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              "：" + item.plnr
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "reply-date" },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.dsCreateTime
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        item.replyType == "topic" &&
                                        item.replyData
                                          ? _c(
                                              "div",
                                              { staticClass: "topic-comment" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "reply-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDetail(
                                                          item.replyData,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "title ellipsis",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.replyData
                                                                .topicName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "info ellipsis",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.replyData
                                                            .topicDesc
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "topic-user-name",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "left" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "name n-t ellipsis",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-user",
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                item.replyData
                                                                  .userName ||
                                                                  "匿名"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "time n-t",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.replyData
                                                                    .publishDate
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reply-icons",
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            class: [
                                                              "el-icon-star-on",
                                                              {
                                                                active:
                                                                  item.replyData
                                                                    .collected ==
                                                                  1,
                                                              },
                                                            ],
                                                            attrs: {
                                                              title: "收藏",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.star(
                                                                  item.replyData
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.replyData
                                                                    .collectNum ||
                                                                    0
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "el-icon-view",
                                                            attrs: {
                                                              title: "点击量",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.replyData
                                                                    .viewNum ||
                                                                    0
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "el-icon-chat-line-square",
                                                            attrs: {
                                                              title: "回复",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.replyData
                                                                    .replyNum ||
                                                                    0
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.replyType == "comment" &&
                                        item.replyData
                                          ? _c(
                                              "div",
                                              { staticClass: "topic-comment" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "user-head" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-photo",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.userInfo(
                                                              item.dsCreateUserId
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-avatar",
                                                          {
                                                            attrs: {
                                                              src:
                                                                "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                                item.replyData
                                                                  .dsCreateUserId,
                                                            },
                                                            on: {
                                                              error:
                                                                _vm.errorHandler,
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-picture-outline-round",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px",
                                                                "line-height":
                                                                  "40px",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "reply-box",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.replyData
                                                                    .dsCreateUserName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "reply-date",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.replyData
                                                                    .dsCreateTime
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "reply-content",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "info ellipsis",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.replyData.plnr
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDetail(
                                                            item,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }
                              )
                            : _c("div", { staticClass: "not-data" }, [
                                _vm._v("暂无数据"),
                              ]),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c(
                        "el-card",
                        { staticClass: "hot-invitation" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-title",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c("h3", [_vm._v("全部论坛热帖")]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSelect("")
                                    },
                                  },
                                },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                          _vm.userData.hotTopics
                            ? _vm._l(_vm.userData.hotTopics, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.topicId,
                                    staticClass: "hot-list",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(item, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title ellipsis",
                                        attrs: { title: item.topicName },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.topicName) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "name n-t ellipsis",
                                        attrs: {
                                          title: item.userName || "匿名",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-user",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(item.userName || "匿名")
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "time" }, [
                                      _vm._v(_vm._s(item.publishDate)),
                                    ]),
                                  ]
                                )
                              })
                            : _c("div", { staticClass: "not-data" }, [
                                _vm._v("暂无数据"),
                              ]),
                        ],
                        2
                      ),
                      _c(
                        "el-card",
                        { staticClass: "hot-reply" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-title",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [_c("h3", [_vm._v("最新动态")])]
                          ),
                          _vm.latestDynamic && _vm.latestDynamic.length
                            ? _vm._l(_vm.latestDynamic, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.dynamicLoading,
                                        expression: "dynamicLoading",
                                      },
                                    ],
                                    key: index,
                                    staticClass: "reply-list",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "reply-item" },
                                      [
                                        item.p_type == "topic"
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "user-head" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "user-photo",
                                                        {
                                                          "user-photo-cursor":
                                                            item.topic_user_id !=
                                                            _vm.userId,
                                                        },
                                                      ],
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.userInfo(
                                                            item.topic_user_id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-avatar",
                                                        {
                                                          attrs: {
                                                            src:
                                                              "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                              item.topic_user_id,
                                                          },
                                                          on: {
                                                            error:
                                                              _vm.errorHandler,
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-picture-outline-round",
                                                            staticStyle: {
                                                              "font-size":
                                                                "20px",
                                                              "line-height":
                                                                "40px",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "reply-box",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "name" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_user_name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reply-date",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.topic_publish_date
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDetail(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "reply-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "title ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.topic_title
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "info ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_content
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "reply-icons",
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          class: [
                                                            "el-icon-star-on",
                                                            {
                                                              active:
                                                                item.collected ==
                                                                1,
                                                            },
                                                          ],
                                                          attrs: {
                                                            title: "收藏",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.star(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_collect_num ||
                                                                  0
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "el-icon-view",
                                                          attrs: {
                                                            title: "点击量",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_view_num ||
                                                                  0
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "el-icon-chat-line-square",
                                                          attrs: {
                                                            title: "回复",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_reply_num
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        item.p_type == "topic_comment"
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "user-head" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "user-photo",
                                                        {
                                                          "user-photo-cursor":
                                                            item.comment_user_id !=
                                                            _vm.userId,
                                                        },
                                                      ],
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.userInfo(
                                                            item.comment_user_id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-avatar",
                                                        {
                                                          attrs: {
                                                            src:
                                                              "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                              item.comment_user_id,
                                                          },
                                                          on: {
                                                            error:
                                                              _vm.errorHandler,
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-picture-outline-round",
                                                            staticStyle: {
                                                              "font-size":
                                                                "20px",
                                                              "line-height":
                                                                "40px",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "reply-box",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "name" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.comment_user_name
                                                              ) +
                                                              " "
                                                          ),
                                                          item.comment_plnr
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "comment-plnr",
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    "：" +
                                                                      item.comment_plnr
                                                                  ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reply-date",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.comment_time
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "reply-button",
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleDetail(
                                                                    item,
                                                                    $event
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v("回复")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "topic-comment",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDetail(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "reply-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "title ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.topic_title
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "info ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.topic_content
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "topic-user-name",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "left" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "name n-t ellipsis",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-user",
                                                              }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.topic_user_name ||
                                                                    "匿名"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "time n-t",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.topic_publish_date
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reply-icons",
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              class: [
                                                                "el-icon-star-on",
                                                                {
                                                                  active:
                                                                    item.collected ==
                                                                    1,
                                                                },
                                                              ],
                                                              attrs: {
                                                                title: "收藏",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.star(
                                                                    item
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.topic_collect_num ||
                                                                      0
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "el-icon-view",
                                                              attrs: {
                                                                title: "点击量",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.topic_view_num ||
                                                                      0
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "el-icon-chat-line-square",
                                                              attrs: {
                                                                title: "回复",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.topic_reply_num ||
                                                                      0
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        item.p_type == "comment"
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "user-head" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        "user-photo",
                                                        {
                                                          "user-photo-cursor":
                                                            item.comment_user_id !=
                                                            _vm.userId,
                                                        },
                                                      ],
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.userInfo(
                                                            item.comment_user_id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-avatar",
                                                        {
                                                          attrs: {
                                                            src:
                                                              "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                              item.comment_user_id,
                                                          },
                                                          on: {
                                                            error:
                                                              _vm.errorHandler,
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-picture-outline-round",
                                                            staticStyle: {
                                                              "font-size":
                                                                "20px",
                                                              "line-height":
                                                                "40px",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "reply-box",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "name" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.comment_user_name
                                                              ) +
                                                              " "
                                                          ),
                                                          item.comment_plnr
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "comment-plnr",
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    "：" +
                                                                      item.comment_plnr
                                                                  ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reply-date",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.comment_time
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "topic-comment",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDetail(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "user-head",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: [
                                                            "user-photo",
                                                            {
                                                              "user-photo-cursor":
                                                                item.p_comment_user_id !=
                                                                _vm.userId,
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.userInfo(
                                                                item.p_comment_user_id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-avatar",
                                                            {
                                                              attrs: {
                                                                src:
                                                                  "/oua2/getUserPhoto?type=relativePath&userId=" +
                                                                  item.p_comment_user_id,
                                                              },
                                                              on: {
                                                                error:
                                                                  _vm.errorHandler,
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-picture-outline-round",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "20px",
                                                                  "line-height":
                                                                    "40px",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "reply-box",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.p_comment_user_name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "reply-date",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.p_comment_time
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "reply-content",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "info ellipsis",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.p_comment_plnr
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              })
                            : _c("div", { staticClass: "not-data" }, [
                                _vm._v("暂无数据"),
                              ]),
                          _vm.scrollDisabled &&
                          _vm.latestDynamic &&
                          _vm.latestDynamic.length
                            ? _c("p", { staticClass: "scroll-more" }, [
                                _vm._v("没有更多了"),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }