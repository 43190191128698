var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-content" },
    [
      !_vm.listData || !_vm.listData.length
        ? _c("div", { staticClass: "not-data" }, [_vm._v("暂无数据")])
        : [
            _vm._l(_vm.listData, function (item) {
              return _c(
                "div",
                {
                  key: item.topicId,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.handleDetail(item, $event)
                    },
                  },
                },
                [
                  _vm.managerStatus
                    ? _c("div", { staticClass: "topic-review-status" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.showStatusText == "不通过",
                              expression: "item.showStatusText == '不通过'",
                            },
                          ],
                          staticClass: "review-status-img",
                          attrs: { src: "/static/img/icon-btg.png" },
                        }),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.showStatusText == "已发布",
                              expression: "item.showStatusText == '已发布'",
                            },
                          ],
                          staticClass: "review-status-img",
                          attrs: { src: "/static/img/badge-published.png" },
                        }),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.showStatusText == "审核中",
                              expression: "item.showStatusText == '审核中'",
                            },
                          ],
                          staticClass: "review-status-img",
                          attrs: { src: "/static/img/icon-shz.png" },
                        }),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.showStatusText == "已关闭",
                              expression: "item.showStatusText == '已关闭'",
                            },
                          ],
                          staticClass: "review-status-img",
                          attrs: { src: "/static/img/badge-closed.png" },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "topic-mannager" }, [
                    _c("div", { staticClass: "topic-name ellipsis" }, [
                      item.isTop && item.isTop == 1
                        ? _c("span", [_vm._v("置顶")])
                        : _vm._e(),
                      item.good ? _c("span", [_vm._v("精")]) : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            "【" + item.catalogName + "】" + item.topicName
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("p", {
                    staticClass: "topic-desc ellipsis",
                    domProps: { innerHTML: _vm._s(item.topicDesc) },
                  }),
                  item.topicImg && item.topicImg.length
                    ? _c(
                        "div",
                        { staticClass: "topic-img" },
                        _vm._l(item.topicImg, function (img, index) {
                          return _c("el-image", {
                            key: index,
                            attrs: {
                              src: img,
                              "preview-src-list": item.topicImg,
                              fit: "cover",
                              title: "图片",
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "topic-user-name" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("div", { staticClass: "name n-t" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(" " + _vm._s(item.userName || "匿名") + " "),
                      ]),
                      _c("div", { staticClass: "time n-t" }, [
                        _vm._v(_vm._s(item.publishDate)),
                      ]),
                      item.belongText
                        ? _c("div", { staticClass: "time n-t" }, [
                            _vm._v(_vm._s(item.belongText)),
                          ])
                        : _vm._e(),
                    ]),
                    !_vm.managerStatus
                      ? _c("div", { staticClass: "reply-icons" }, [
                          _c(
                            "i",
                            {
                              class: [
                                item.collected == 1
                                  ? "el-icon-star-on"
                                  : "el-icon-star-off",
                                { active: item.collected == 1 },
                              ],
                              attrs: { title: "收藏" },
                              on: {
                                click: function ($event) {
                                  return _vm.star(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.collectNum || 0) + " ")]
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-view",
                              attrs: { title: "点击量" },
                            },
                            [_vm._v(" " + _vm._s(item.viewNum || 0))]
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-chat-line-square",
                              attrs: { title: "回复" },
                            },
                            [_vm._v(" " + _vm._s(item.replyNum || 0))]
                          ),
                        ])
                      : _vm._e(),
                    _vm.managerStatus
                      ? _c(
                          "div",
                          { staticClass: "reply-icons" },
                          [
                            item.isPublish === "1"
                              ? _c(
                                  "DsfButton",
                                  {
                                    staticClass: "edit",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.startPost(item)
                                      },
                                    },
                                  },
                                  [_vm._v("发布")]
                                )
                              : _vm._e(),
                            item.isClose === "1"
                              ? _c(
                                  "DsfButton",
                                  {
                                    staticClass: "edit",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.closePost(item)
                                      },
                                    },
                                  },
                                  [_vm._v("关闭帖子")]
                                )
                              : _vm._e(),
                            item.isPass === "1"
                              ? _c(
                                  "DsfButton",
                                  {
                                    staticClass: "edit",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.passPost(item)
                                      },
                                    },
                                  },
                                  [_vm._v("审核通过")]
                                )
                              : _vm._e(),
                            item.isNoPass === "1"
                              ? _c(
                                  "DsfButton",
                                  {
                                    staticClass: "edit",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.rejectPost(item)
                                      },
                                    },
                                  },
                                  [_vm._v("不通过")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            _vm.showPage
              ? _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next",
                    "current-page": _vm.pageNumber,
                    "page-size": _vm.pageSize,
                    total: _vm.total,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      _vm.pageNumber = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.pageNumber = $event
                    },
                    "current-change": _vm.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帖子详情",
            visible: _vm.dialogVisible,
            width: "60%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.detailContent.content
            ? _c(
                "div",
                {
                  staticClass: "bbs-detail",
                  staticStyle: {
                    height: "60vh",
                    "overflow-y": "auto",
                    padding: "20px 50px",
                    "box-sizing": "border-box",
                    "margin-bottom": "30px",
                    "text-align": "center",
                    "background-color": "#fff",
                  },
                },
                [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.detailContent.content.topicName)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "detail-info",
                      staticStyle: { margin: "30px 0", color: "#a5aeb6" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "楼主: " +
                            _vm._s(_vm.detailContent.content.userName) +
                            " "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          "时间: " +
                            _vm._s(_vm.detailContent.content.dsCreateTime)
                        ),
                      ]),
                      _vm.detailContent.content.belongName
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.detailContent.content.belongName)
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("div", {
                    staticClass: "detail-content",
                    staticStyle: {
                      "border-top": "1px solid #dbdbdb",
                      padding: "30px 20px 0 20px",
                      "text-align": "left",
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.detailContent.content.topicContent),
                    },
                  }),
                  _vm.detailContent.content.topicImg &&
                  _vm.detailContent.content.topicImg.length
                    ? _c(
                        "div",
                        {
                          staticClass: "detail-imgs",
                          staticStyle: {
                            display: "block",
                            margin: "20px auto",
                            "max-width": "969px",
                            width: "auto",
                          },
                        },
                        _vm._l(
                          _vm.detailContent.content.topicImg,
                          function (img, index) {
                            return _c("el-image", {
                              key: index,
                              attrs: { src: img, fit: "cover" },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
            _vm.detailContent.content
              ? _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _vm.dialogPost.isPublish === "1"
                      ? _c(
                          "DsfButton",
                          {
                            staticClass: "edit",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.startPost(_vm.detailContent.content)
                              },
                            },
                          },
                          [_vm._v("发布")]
                        )
                      : _vm._e(),
                    _vm.dialogPost.isClose === "1"
                      ? _c(
                          "DsfButton",
                          {
                            staticClass: "edit",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.closePost(_vm.detailContent.content)
                              },
                            },
                          },
                          [_vm._v("关闭帖子")]
                        )
                      : _vm._e(),
                    _vm.dialogPost.isPass === "1"
                      ? _c(
                          "DsfButton",
                          {
                            staticClass: "edit",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.passPost(_vm.detailContent.content)
                              },
                            },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm.dialogPost.isNoPass === "1"
                      ? _c(
                          "DsfButton",
                          {
                            staticClass: "edit",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.rejectPost(_vm.detailContent.content)
                              },
                            },
                          },
                          [_vm._v("不通过")]
                        )
                      : _vm._e(),
                    _c(
                      "DsfButton",
                      {
                        attrs: { size: "small", type: "plain" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }