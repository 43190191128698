<template>
  <dsf-bbs-views :show-sorted="false">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <template v-for="item in tabPane">
        <el-tab-pane :key="item.name" :label="item.label" :name="item.name">
          <dsf-list-content
            v-if="item.name == activeName && listContent[activeName]"
            :list-content="listContent[activeName]"
            @page-event="queryData"
          />
        </el-tab-pane>
      </template>
    </el-tabs>
  </dsf-bbs-views>
</template>

<script>
import DsfBbsViews from "../components/BbsViews";
import DsfListContent from "../components/ListContent";
export default {
  name: "DsfMyPost",
  data() {
    return {
      queryParams: {
        pageNumber: 1,
        pageSize: 10,
        type: -1,
      },
      tabPane: [
        {
          name: "allPage",
          label: "全部",
        },
        {
          name: "close",
          label: "已关闭",
        },
      ],
      queryUrl: "web/topic/findTopicByUserId",
      activeName: "allPage",
      listContent: {
        allPage: null,
        close: null,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
  methods: {
    queryData(pageSize) {
      // 最新 最热
      if (dsf.isDef(pageSize)) {
        this.queryParams.pageNumber = pageSize;
      }
      this.$http
        .get(this.queryUrl, this.queryParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.listContent[this.activeName] = res.data;
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    handleClick(tab) {
      this.queryParams.type = tab.index == 1 ? 3 : -1;
      this.queryData();
    },
  },
  components: {
    DsfBbsViews,
    DsfListContent,
  },
};
</script>
