<template>
  <el-container class="ds-bbs-page bbs-view">
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: 'home' }"><i class="iconfont icon-home"></i> 论坛首页</el-breadcrumb-item>
        <!-- 'allPage?c=' + $route.query.catalogId  -->
        <el-breadcrumb-item :to="{ path: path }" v-if="$route.name != 'allPage' && $route.name != 'myPost' && $route.name != 'myReply'">{{
          secondURL ? secondURL : "所有帖子"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle || $route.params.title || $route.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-button class="create-post" v-if="showSorted && !managerStatus" @click="handleClick">发布帖子</el-button>
    </el-header>
    <DsfVirtualScroll class="list">
      <el-main>
        <slot />
        <div class="sort-button" v-if="showSorted && $route.meta.title != '帖子详情'">
          <template v-for="(item, index) in sortedData">
            <span v-if="!managerStatus" :key="index" :class="{ active: index == checkSort }" @click="handleSorted(index, item.text)">
              {{ item.text }}
            </span>
          </template>
          <el-select v-if="managerStatus" v-model="selectvalue" placeholder="请选择" @change="changeType">
            <el-option v-for="item in selection" :key="item.text" :label="item.text" :value="item.text"> </el-option>
          </el-select>
        </div>
      </el-main>
    </DsfVirtualScroll>
  </el-container>
</template>

<script>
export default {
  name: "DsfBbsViews",
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    showSorted: {
      type: Boolean,
      default: true,
    },
    managerStatus: {
      type: Boolean,
      default: false,
    },
    secondURL: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      path: "allPage?cname=所有帖子",
      userStatus: false,
      sortedData: [
        {
          text: "最新",
        },
        {
          text: "最热",
        },
      ],
      selectvalue: "全部",
      selection: [
        {
          text: "全部",
        },
        {
          text: "待审核",
        },
        {
          text: "未通过",
        },
        {
          text: "已发布",
        },
        {
          text: "已关闭",
        },
      ],
      checkSort: 0,
    };
  },
  created() {
    if (this.$route.query.c) {
      this.path = "allPage?c=" + this.$route.query.c + "&cname=" + this.$route.query.cname;
    }
    if (this.$route.query.u) {
      this.path = "allPage?u=" + this.$route.query.u + "&cname=" + this.$route.query.cname;
    }
    if (this.secondURL && this.secondURL === "我的帖子") {
      this.path = "myPost";
    } else if (this.secondURL && this.secondURL === "我的收藏") {
      this.path = "allPage?star=1";
    } else if (this.secondURL && this.secondURL.includes("回复")) {
      if (this.$route.query.u) {
        this.path = "myReply?u=" + this.$route.query.u;
      } else {
        this.path = "myReply";
      }
    }

    document.title = this.pageTitle || this.$route.params.title || this.$route.meta.title;
    this.userStatus = this.$route.query.u ? true : false;
  },
  methods: {
    handleClick() {
      this.$openDialog({
        title: "发布帖子",
        width: "80%",
        height: "80vh",
        overflow: "auto",
        params: {
          path: "bbs/topic/edit?close=2",
        },
        btns: [],
      });
    },
    changeType(val) {
      // console.log(val, " -changeType view");
      this.$emit("sorted", 0, val);
    },
    handleSorted(type, text) {
      this.checkSort = type;
      this.$emit("sorted", type, text);
    },
  },
};
</script>
