var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dsf-bbs-views",
    {
      staticClass: "bbs-detail-views",
      attrs: { secondURL: _vm.$route.query.cname },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          ref: "detail",
          staticClass: "bbs-detail",
        },
        [
          _vm.detailContent
            ? [
                _c("h3", [_vm._v(_vm._s(_vm.detailContent.content.topicName))]),
                _c("p", { staticClass: "detail-info" }, [
                  _c("span", [
                    _vm._v(
                      "楼主: " +
                        _vm._s(_vm.detailContent.content.userName) +
                        " "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "时间: " + _vm._s(_vm.detailContent.content.dsCreateTime)
                    ),
                  ]),
                  _vm.detailContent.content.belongName
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.detailContent.content.belongName)
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", {
                  staticClass: "detail-content",
                  domProps: {
                    innerHTML: _vm._s(_vm.detailContent.content.topicContent),
                  },
                }),
                _vm.detailContent.content.topicImg &&
                _vm.detailContent.content.topicImg.length
                  ? _c(
                      "div",
                      { staticClass: "detail-imgs" },
                      _vm._l(
                        _vm.detailContent.content.topicImg,
                        function (img, index) {
                          return _c("el-image", {
                            key: index,
                            attrs: {
                              src: img,
                              fit: "cover",
                              "preview-src-list":
                                _vm.detailContent.content.topicImg,
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                _vm.detailContent.content.topicFile
                  ? _c("dsf-upload-file", {
                      attrs: {
                        value: _vm.detailContent.content.topicFile,
                        "read-only": true,
                        label: "附件",
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "bottom-buttons" }, [
                  _c("div"),
                  _c(
                    "div",
                    { staticClass: "like-button" },
                    [
                      _vm.detailContent.content.userId
                        ? _c(
                            "el-button",
                            {
                              class: {
                                active: _vm.detailContent.content.liked == 1,
                              },
                              attrs: { disabled: _vm.like },
                              on: {
                                click: function ($event) {
                                  return _vm.liked(
                                    _vm.detailContent.content.tagsNum
                                  )
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-dianzan_kuai",
                              }),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.detailContent.content.tagsNum)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.detailContent.content.userId
                    ? _c(
                        "div",
                        { staticClass: "right-wrap" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-chat-line-square",
                                size: "small",
                              },
                              on: { click: _vm.scrollFooter },
                            },
                            [_vm._v("回复")]
                          ),
                          _c(
                            "el-button",
                            {
                              class: {
                                active:
                                  _vm.detailContent.content.collected == 1,
                              },
                              attrs: {
                                icon:
                                  "el-icon-star-" +
                                  "" +
                                  (_vm.detailContent.content.collected == 1
                                    ? "on"
                                    : "off"),
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.star(
                                    _vm.detailContent.content.collected
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detailContent.content.collected == 1
                                      ? "已收藏"
                                      : "收藏"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.checkTpye },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.queryParams.vType == 0
                                    ? "只看楼主"
                                    : "全部"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            : [
                _c("div", { staticClass: "not-data" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detailLoading ? "加载中..." : "暂无数据") +
                      " "
                  ),
                ]),
              ],
        ],
        2
      ),
      _c("bbs-reply-content", {
        ref: "bbsReplyContent",
        attrs: { "detail-content": _vm.detailContent },
        on: { queryData: _vm.queryData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }