var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-bbs-emoji" },
    _vm._l(_vm.emojis, function (item) {
      return _c("img", {
        key: item.key,
        attrs: { src: item.value, alt: "" },
        on: {
          click: function ($event) {
            return _vm.handleClick(item)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }