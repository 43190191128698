import { render, staticRenderFns } from "./DsfPcReplyComponent.vue?vue&type=template&id=5b835d7c&"
import script from "./DsfPcReplyComponent.vue?vue&type=script&lang=js&"
export * from "./DsfPcReplyComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "pc/components/DsfPcReplyComponent.vue"
export default component.exports