<template>
  <el-container class="ds-bbs-page" v-loading="bbsLoading">
    <el-aside width="240px">
      <div class="head-img">
        <el-avatar :src="userPhoto" @error="errorHandler" v-if="userId">
          <img :src="dsf.config.setting_public_user_default_header" alt="" />
        </el-avatar>
        <el-row type="flex" justify="center" v-if="userData.user">
          <el-col>
            <p>{{ userData.user.replies || 0 }}</p>
            <p>回复</p>
          </el-col>
          <el-col>
            <p>{{ userData.user.like || 0 }}</p>
            <p>点赞</p>
          </el-col>
          <el-col>
            <p>{{ userData.user.liked || 0 }}</p>
            <p class="txt">被赞</p>
          </el-col>
        </el-row>
      </div>
      <DsfVirtualScroll class="list">
        <div class="bbs-nav-menu">
          <el-menu :default-active="defaultActive" :default-openeds="defaultOpeneds" @select="handleSelect" @open="handleOpen">
            <template v-for="(meun, index) in menuNav">
              <el-menu-item :key="index" :index="meun.path + '-' + meun.catalogName" v-if="meun.viewStatus === '1' && !meun.children">
                <i :class="meun.icon"></i>
                <span slot="title">{{ meun.catalogName }} </span>
              </el-menu-item>
              <el-submenu :data-id="index" :key="index" :index="meun.path" v-else-if="meun.viewStatus === '1'">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{ meun.catalogName }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :title="child.catalogName"
                    :index="child.catalogId + '-' + child.catalogName"
                    v-for="(child, key) in meun.children"
                    :key="key"
                  >
                    {{ child.catalogName }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </template>
          </el-menu>
        </div>
      </DsfVirtualScroll>
    </el-aside>

    <el-main
      width="940px"
      v-infinite-scroll="userDynamic"
      style="overflow: auto"
      :infinite-scroll-disabled="scrollDisabled"
      :infinite-scroll-delay="500"
    >
      <dsf-view-part :path="postReview" :show-model="'full'" v-if="publishTheme" :independent="true" />
      <template v-else>
        <template v-if="userData.accessType != 1">
          <div class="other-card">
            <div class="other-name">
              {{ userData.user && userData.user.userName }}
              <i class="el-icon-user-solid"></i>
            </div>
            <el-button @click="handleFollow" size="small" plain :icon="followed ? 'el-icon-close' : 'el-icon-plus'">
              {{ followed ? "取消关注" : "关注" }}
            </el-button>
          </div>
          <el-card class="hot-invitation other-content">
            <div slot="header" class="top-title">
              <h3>他的帖子</h3>
              <el-button type="text" icon="el-icon-plus" @click="handleOther('other')">更多</el-button>
            </div>
            <dsf-list-content :list-content="userData.otherTopics" :show-page="false" />
          </el-card>
          <el-card class="hot-reply">
            <div slot="header" class="top-title">
              <h3>他的回复</h3>
              <el-button type="text" icon="el-icon-plus" @click="handleOther('reply')">更多</el-button>
            </div>
            <template v-if="userData.reply && userData.reply.data">
              <div v-for="(item, index) in userData.reply.data" :key="index" class="reply-list">
                <div class="reply-item">
                  <div class="user-head">
                    <div class="user-photo">
                      <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
                        <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                      </el-avatar>
                    </div>
                    <div class="reply-box">
                      <div class="name">
                        {{ item.dsCreateUserName }}
                        <span class="comment-plnr" v-if="item.plnr" v-html="'：' + item.plnr"></span>
                        <!-- <div v-if="item.img && item.img.length" class="img-list">
                            <el-image v-for="(img, index) in item.img" :key="index" :src="img" fit="cover" @click="showImage" />
                          </div> -->
                      </div>
                      <div class="reply-date">
                        <div>{{ item.dsCreateTime }}</div>
                        <!-- <div v-if="item.replyType != 'topic'" class="reply-button" @click="handleDetail('item')">回复</div> -->
                      </div>
                    </div>
                  </div>
                  <!-- 个人 好像没有这些 -->
                  <!-- <template v-if="item.replyType == 'topic' && item.replyData">
                      <div class="reply-content">
                        <div class="title ellipsis">{{ item.replyData.topicName }}</div>
                        <div class="info ellipsis">{{ item.replyData.topicDesc }}</div>
                      </div>
                      <div class="reply-icons">
                        <i class="el-icon-star-on active" title="收藏"> {{ item.replyData.collectNum }}</i>
                        <i class="el-icon-view" title="点击量"> {{ item.replyData.viewNum }}</i>
                        <i class="el-icon-chat-line-square" title="回复"> {{ item.replyData.replyNum }}</i>
                      </div>
                    </template> -->
                  <div class="topic-comment" v-if="item.replyType == 'topic' && item.replyData">
                    <div class="reply-content" @click="handleDetail(item.replyData, $event)">
                      <div class="title ellipsis">
                        {{ item.replyData.topicName }}
                      </div>
                      <div class="info ellipsis" v-html="item.replyData.topicDesc">
                        <!-- {{ item.replyData.topicDesc }} -->
                      </div>
                    </div>
                    <div class="topic-user-name">
                      <div class="left">
                        <div class="name n-t ellipsis"><i class="el-icon-user"></i>{{ item.replyData.userName || "匿名" }}</div>
                        <div class="time n-t">
                          {{ item.replyData.publishDate }}
                        </div>
                      </div>
                      <div class="reply-icons">
                        <!-- :class="[item.collected == 1 ? 'el-icon-star-on' : 'el-icon-star-off', { active: item.collected == 1 }]"
              @click="star(item)" -->

                        <i :class="['el-icon-star-on', { active: item.replyData.collected == 1 }]" title="收藏" @click="star(item.replyData)">
                          {{ item.replyData.collectNum || 0 }}
                        </i>
                        <i class="el-icon-view" title="点击量"> {{ item.replyData.viewNum || 0 }}</i>
                        <i class="el-icon-chat-line-square" title="回复"> {{ item.replyData.replyNum || 0 }}</i>
                      </div>
                    </div>
                  </div>
                  <div class="topic-comment" v-if="item.replyType == 'comment' && item.replyData">
                    <div class="user-head">
                      <div class="user-photo" @click="userInfo(item.dsCreateUserId)">
                        <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.replyData.dsCreateUserId" @error="errorHandler">
                          <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                        </el-avatar>
                      </div>
                      <div class="reply-box">
                        <p class="name">
                          {{ item.replyData.dsCreateUserName }}
                        </p>
                        <div class="reply-date">
                          <div>{{ item.replyData.dsCreateTime }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="reply-content">
                      <div class="info ellipsis" @click="handleDetail(item, $event)" v-html="item.replyData.plnr"></div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
          </el-card>
        </template>
        <template v-else>
          <el-card class="hot-invitation">
            <div slot="header" class="top-title">
              <h3>全部论坛热帖</h3>
              <el-button type="text" icon="el-icon-plus" @click="handleSelect('')">更多</el-button>
            </div>
            <template v-if="userData.hotTopics">
              <div v-for="item in userData.hotTopics" :key="item.topicId" class="hot-list" @click="handleDetail(item, $event)">
                <div class="title ellipsis" :title="item.topicName">
                  {{ item.topicName }}
                </div>
                <div class="name n-t ellipsis" :title="item.userName || '匿名'"><i class="el-icon-user"></i> {{ item.userName || "匿名" }}</div>
                <div class="time">{{ item.publishDate }}</div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
          </el-card>
          <el-card class="hot-reply">
            <div slot="header" class="top-title">
              <h3>最新动态</h3>
              <!-- <el-button
                type="text"
                icon="el-icon-plus"
                @click="handleSelect('myReply-我的回复')"
              >更多</el-button
              > -->
            </div>
            <template v-if="latestDynamic && latestDynamic.length">
              <div v-for="(item, index) in latestDynamic" :key="index" class="reply-list" v-loading="dynamicLoading">
                <div class="reply-item">
                  <template v-if="item.p_type == 'topic'">
                    <div class="user-head">
                      <div :class="['user-photo', { 'user-photo-cursor': item.topic_user_id != userId }]" @click="userInfo(item.topic_user_id)">
                        <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.topic_user_id" @error="errorHandler">
                          <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                        </el-avatar>
                      </div>
                      <div class="reply-box">
                        <div class="name">
                          {{ item.topic_user_name }}
                          <!-- <span class="comment-plnr" v-if="item.comment_plnr" v-html="'：' + item.comment_plnr"></span>
                            <div v-if="item.img && item.img.length" class="img-list">
                              <el-image v-for="(img, index) in item.img" :key="index" :src="img" fit="cover" @click="showImage" />
                            </div> -->
                        </div>
                        <div class="reply-date">
                          <div>{{ item.topic_publish_date }}</div>
                        </div>
                      </div>
                    </div>
                    <div @click="handleDetail(item, $event)">
                      <div class="reply-content">
                        <div class="title ellipsis">{{ item.topic_title }}</div>
                        <div class="info ellipsis">
                          {{ item.topic_content }}
                        </div>
                      </div>
                      <div class="reply-icons">
                        <i :class="['el-icon-star-on', { active: item.collected == 1 }]" title="收藏" @click="star(item)">
                          {{ item.topic_collect_num || 0 }}
                        </i>
                        <i class="el-icon-view" title="点击量">
                          {{ item.topic_view_num || 0 }}
                        </i>
                        <i class="el-icon-chat-line-square" title="回复">
                          {{ item.topic_reply_num }}
                        </i>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.p_type == 'topic_comment'">
                    <div class="user-head">
                      <div :class="['user-photo', { 'user-photo-cursor': item.comment_user_id != userId }]" @click="userInfo(item.comment_user_id)">
                        <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.comment_user_id" @error="errorHandler">
                          <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                        </el-avatar>
                      </div>
                      <div class="reply-box">
                        <div class="name">
                          {{ item.comment_user_name }}
                          <span class="comment-plnr" v-if="item.comment_plnr" v-html="'：' + item.comment_plnr"></span>
                          <!-- <div v-if="item.img && item.img.length" class="img-list">
                              <el-image v-for="(img, index) in item.img" :key="index" :src="img" fit="cover" @click="showImage" />
                            </div> -->
                        </div>
                        <div class="reply-date">
                          <div>{{ item.comment_time }}</div>
                          <div class="reply-button" @click="handleDetail(item, $event)">回复</div>
                        </div>
                      </div>
                    </div>
                    <div class="topic-comment" @click="handleDetail(item, $event)">
                      <div class="reply-content">
                        <div class="title ellipsis">{{ item.topic_title }}</div>
                        <div class="info ellipsis">
                          {{ item.topic_content }}
                        </div>
                      </div>
                      <div class="topic-user-name">
                        <div class="left">
                          <div class="name n-t ellipsis"><i class="el-icon-user"></i>{{ item.topic_user_name || "匿名" }}</div>
                          <div class="time n-t">
                            {{ item.topic_publish_date }}
                          </div>
                        </div>
                        <div class="reply-icons">
                          <i :class="['el-icon-star-on', { active: item.collected == 1 }]" title="收藏" @click="star(item)">
                            {{ item.topic_collect_num || 0 }}
                          </i>
                          <i class="el-icon-view" title="点击量">
                            {{ item.topic_view_num || 0 }}
                          </i>
                          <i class="el-icon-chat-line-square" title="回复">
                            {{ item.topic_reply_num || 0 }}
                          </i>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.p_type == 'comment'">
                    <div class="user-head">
                      <div :class="['user-photo', { 'user-photo-cursor': item.comment_user_id != userId }]" @click="userInfo(item.comment_user_id)">
                        <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.comment_user_id" @error="errorHandler">
                          <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                        </el-avatar>
                      </div>
                      <div class="reply-box">
                        <div class="name">
                          {{ item.comment_user_name }}
                          <span class="comment-plnr" v-if="item.comment_plnr" v-html="'：' + item.comment_plnr"></span>
                        </div>
                        <div class="reply-date">
                          <div>{{ item.comment_time }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="topic-comment" @click="handleDetail(item, $event)">
                      <div class="user-head">
                        <div
                          :class="['user-photo', { 'user-photo-cursor': item.p_comment_user_id != userId }]"
                          @click="userInfo(item.p_comment_user_id)"
                        >
                          <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.p_comment_user_id" @error="errorHandler">
                            <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                          </el-avatar>
                        </div>
                        <div class="reply-box">
                          <p class="name">{{ item.p_comment_user_name }}</p>
                          <div class="reply-date">
                            <div>{{ item.p_comment_time }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="reply-content">
                        <div class="info ellipsis" v-html="item.p_comment_plnr"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <div class="not-data" v-else>暂无数据</div>
            <!-- v-if="scrollDisabled && queryParams.pageNumber > 1" -->
            <p v-if="scrollDisabled && latestDynamic && latestDynamic.length" class="scroll-more">没有更多了</p>
          </el-card>
        </template>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import DsfListContent from "../components/ListContent";
export default {
  name: "DsfHomePage",
  data() {
    return {
      defaultActive: "home-论坛首页",
      defaultOpeneds: [],
      menuNav: [
        {
          catalogName: "论坛首页",
          icon: "el-icon-s-home",
          path: "home",
          viewStatus: "1",
        },
        {
          catalogName: "帖子板块",
          icon: "el-icon-document",
          path: "",
          viewStatus: "1",
          children: [
            {
              catalogId: "",
              catalogName: "所有帖子",
              path: "allPage",
              viewStatus: "1",
            },
          ],
        },
        {
          catalogName: "我的帖子",
          icon: "el-icon-document",
          path: "myPost",
          viewStatus: "1",
        },
        {
          catalogName: "我的回复",
          icon: "el-icon-chat-line-square",
          path: "myReply",
          viewStatus: "1",
        },
        {
          catalogName: "我的收藏",
          icon: "el-icon-star-off",
          path: "allPage",
          viewStatus: "1",
        },
        // {
        //   catalogName: "精华帖子",
        //   icon: "icon-shouyexuanzhong",
        //   path: "distillatedPosts",
        // },
        // {
        //   catalogName: "帖子审查",
        //   icon: "el-icon-notebook-2",
        //   path: "postCheck",
        // },
        {
          catalogName: "发布帖子",
          icon: "el-icon-document-add",
          path: "createPost",
          viewStatus: "1",
        },
        {
          catalogName: "主题审查",
          icon: "el-icon-s-check",
          path: "allPage",
          viewStatus: "0",
        },
        // {
        //   catalogName: "板块管理",
        //   icon: "icon-shouyexuanzhong",
        //   path: "plateManagement",
        // },
      ],
      bbsLoading: false,
      userId: "",
      topicType: "topic_comment", // topic comment topic_comment
      userData: {},
      postReview: "/pc/bbs/topic/edit?close=1",
      publishTheme: false,
      followed: false,
      isBbsTopicManager: dsf.cookies.get("isBbsTopicManager"),
      isAudit: dsf.cookies.get("isAudit"),
      queryParams: {
        pageNumber: 1,
        hSize: 5,
        nSize: 5,
        userId: "",
      },
      scrollDisabled: false,
      latestDynamic: [],
      dynamicLoading: false,
      reloadHome: false,
      userPhoto: null,
    };
  },
  created() {
    // 获取用户信息
    this.isAudit = this.isAudit && this.isAudit == "true" ? true : false;
    this.queryParams.userId = this.$route.query.u;
    this.queryData();
  },
  mounted() {},
  destroyed() {},
  methods: {
    queryData() {
      this.reloadHome = false;
      this.bbsLoading = true;
      this.$http
        .get("/web/user/center", this.queryParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.menuNav.forEach((item, index) => {
              if (item.catalogName === "主题审查") {
                this.menuNav[index].viewStatus = res.data.hasAuditRole;
              }
            });
            // 关注
            this.followed = res.data.followed == 1 ? true : false;
            // 1自己
            if (res.data.accessType == 1) {
              var first = this.menuNav[1].children[0];
              this.menuNav[1].children = _.concat(
                [first],
                _(res.data.catalogs).forEach((value) => (value.path = "allPage"))
              );
            } else {
              this.menuNav = [
                {
                  catalogName: "论坛首页",
                  icon: "el-icon-s-home",
                  path: "home",
                  viewStatus: "1",
                },
                {
                  catalogName: "所有帖子",
                  icon: "el-icon-document",
                  path: "allPage",
                  viewStatus: "1",
                },
              ];
              this.reloadHome = true;
              this.scrollDisabled = true;
            }
            this.userPhoto = "/oua2/getUserPhoto?type=relativePath&userId=" + res.data.user.userId;
            this.userId = res.data.user.userId;
            this.userData = res.data;
            this.userData.otherTopics = { list: res.data.otherTopics };
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.bbsLoading = false;
        });
    },
    userDynamic() {
      this.$http
        .get("/web/user/dynamic", this.queryParams, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000 && res.data?.list) {
            // 处理滚动翻页
            res.data.list = _.concat(this.latestDynamic, res.data.list);
            if (res.data.totalPage > this.queryParams.pageNumber) {
              this.queryParams.pageNumber++;
            } else {
              this.scrollDisabled = true;
              // this.queryParams.pageNumber = 2; // 强制数据没有更多了
            }

            if (!res.data.list) {
              this.scrollDisabled = true;
            }

            this.latestDynamic = res.data.list;
          } else {
            this.scrollDisabled = true;
            // dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          this.scrollDisabled = true;
          dsf.layer.message(err.message, false);
        })
        .always(() => {});
    },
    handleSelect(key, keyPath) {
      this.defaultActive = key;
      let splitKey = key?.split("-");
      let keyLen = splitKey[0].length;
      if (splitKey[0] == "allPage" && (!splitKey[1] || splitKey[1] == "所有帖子")) {
        this.$router.push({
          name: "allPage",
          params: { title: splitKey[1] },
          query: { cname: splitKey[1] },
        });
        return;
      }
      if (keyLen == 0 || keyLen == 32) {
        this.$router.push({
          name: "allPage",
          params: { title: splitKey[1] },
          query: { c: splitKey[0], cname: splitKey[1] },
        });
      } else {
        this.publishTheme = false;
        if (splitKey[1] == "发布帖子") {
          this.publishTheme = true;
          // this.postReview = "/pc/bbs/topic/edit?close=1";
        } else {
          let path = "home";
          if (splitKey[1] == "我的收藏") {
            path = `/pc/bbs/${splitKey[0]}?star=1`;
          } else if (splitKey[1] == "我的回复") {
            path = `/pc/bbs/${splitKey[0]}`;
          } else if (splitKey[1] == "主题审查") {
            path = `/pc/bbs/${splitKey[0]}?hasAuditRole=1`;
          } else if (splitKey[1] == "我的帖子") {
            path = `/pc/bbs/${splitKey[0]}`;
          }
          if (splitKey[0] !== "home") {
            this.$router.push({ path });
          } else {
            this.$router.replace({ path });
            if (this.reloadHome) {
              location.reload();
            }
          }
        }
      }
    },
    handleDetail(item, e) {
      let topicId = item.topicId || item.topic_id || item.ywid;
      var query = { ...this.$route.query };
      if (topicId && e.target.title != "收藏") {
        query.id = topicId;
        if (this.$route.query.u && this.userData.accessType != 1) {
          query.cname = "他的回复";
        }
        this.$router.push({
          name: "pageDetail",
          params: {},
          query,
        });
      }
    },
    star(item) {
      if (item.collected == 1) {
        dsf.layer
          .confirm({
            title: "信息",
            message: "确认取消收藏吗？",
          })
          .then(() => {
            this.saveOrCancel(item);
          })
          .catch(() => {});
      } else {
        this.saveOrCancel(item);
      }
    },
    saveOrCancel(item) {
      let params = {
        ywlxValue: "bbs_topic",
        ywlxText: "BBS主题",
        ywid: item.topic_id || item.topicId,
      };
      this.$http
        .post("dsfa/collect/saveOrCancel", params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.latestDynamic = _.forEach(this.latestDynamic, (list) => {
              if (item.topic_id === list.topic_id) {
                list.collected = res.data.isDo ? 1 : 0;
                list.topic_collect_num = res.data.isDo ? Number(list.topic_collect_num) + 1 : Number(list.topic_collect_num) - 1;
              }
            });
            if (item.topicId) {
              this.userData.reply.data = _.forEach(this.userData.reply.data, (list) => {
                if (item.topicId === list.replyData.topicId) {
                  list.replyData.collected = res.data.isDo ? 1 : 0;
                  list.replyData.collectNum = res.data.isDo ? Number(list.replyData.collectNum) + 1 : Number(list.replyData.collectNum) - 1;
                }
              });
            }
            dsf.layer.message(res.message);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    handleOther(type) {
      //  他的贴子 &  他的回复
      if (type == "other") {
        this.$router.push({
          name: "allPage",
          params: { title: "他的贴子" },
          query: { u: this.userId },
        });
      } else {
        // console.log(type);
        this.$router.push({
          name: "myReply",
          params: { title: "他的回复" },
          query: { u: this.userId },
        });
      }
    },
    userInfo(uId) {
      // 查看用户
      if (uId != this.userId) {
        this.$openWindow("#/pc/bbs/home?u=" + uId);
      }
    },
    handleFollow() {
      let params = {
        ywlxValue: "bbs_topic",
        ywlxText: "BBS主题",
        ywid: this.userId,
      };
      this.$http
        .post("dsfa/concern/saveOrCancel", params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.followed = res.data.isDo;
            dsf.layer.message(res.message);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    handleOpen(key) {
      this.defaultOpeneds.push(key);
    },
    errorHandler() {
      return true;
    },
  },
  watch: {
    defaultActive(val) {
      if (val == "home-论坛首页") {
        this.queryData();
        this.queryParams.pageNumber = 1;
        this.latestDynamic = [];
        this.userDynamic();
      }
    },
  },
  components: {
    DsfListContent,
  },
};
</script>
