var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pc-error-container" }, [
    _c("div", { staticClass: "pc-error-tips" }, [
      _vm._m(0),
      _c("div", { staticClass: "pc-error-text" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pc-error-pic" }, [
      _c("img", {
        staticClass: "pc-error-img",
        attrs: {
          src: require("../../assets/styles/themes/img/error2.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }