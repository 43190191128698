var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dsf-bbs-views",
    { attrs: { "show-sorted": false } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._l(_vm.tabPane, function (item) {
            return [
              _c(
                "el-tab-pane",
                {
                  key: item.name,
                  attrs: { label: item.label, name: item.name },
                },
                [
                  item.name == _vm.activeName && _vm.listContent[_vm.activeName]
                    ? _c("dsf-list-content", {
                        attrs: {
                          "list-content": _vm.listContent[_vm.activeName],
                        },
                        on: { "page-event": _vm.queryData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }